<template>
  <v-container fluid>
    <page-title title="Что такое арт-терапия на самом деле?" />
    <transition-group appear name="fadeGroup">
      <v-row key="a" justify="space-around" align="center">
        <v-col cols="12" sm="11">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              Давно размышляю над тем, что зачастую то, что называют арт-терапией — не арт-терапия.
              В большинстве случаев. <br /><br />
              Когда человек приходит на арт-терапию, у него есть определенные ожидания. Исходя из
              моего опыта — клиент ожидает, что он будет рисовать. И на самом деле, под арт-терапией
              в подавляющем большинстве случаев понимается именно творчество. То есть терапия
              творчеством. Не смотря на то, что арт — это искусство, и арт-терапия, если рассуждать
              логически, — это терапия искусством. Но слово «арт» простое, удобное и даже модное,
              поэтому арт-терапией называют все, что имеет отношение к творчеству. Часто
              арт-терапией называют любое рисование, потому что дескать во время любого движения
              карандашом по бумаге происходит выброс эндорфинов, что и обеспечивает якобы
              терапевтический эффект (без комментариев, только изумленно поднятая бровь).
              <br /><br />
              Терапевтический же эффект при терапии творчеством и терапией искусством совершенно
              разный. Более того, разные цели, механизмы, техники... Все разное!
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row key="b" justify="center" align="center">
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              <b>Терапия творчеством</b> (дословно creativity therapy, но этот термин не
              используется – то ли потому что название длинное и неудобное, то ли изначально
              произошла путаница определений) — это довольно сложный подход, основанный на знании
              психоанализа, умении анализировать каждое движение клиента, начиная от выбора им
              материала для творчества (и этот выбор надо предоставить), до характера движения
              карандаша или руки. Это наблюдение за чувствами, и переживаниями. Это сопровождение в
              область, куда клиенту страшно заглянуть самому, но при помощи творчества он может
              позволить себе такой опыт переживаний, который не мог позволить себе никогда. Это про
              энергию либидо, когда незримая сила становится материальной. Это про многое. Но в
              последнюю очередь — это просто про порисовать, слепить, сделать по предложенному
              шаблону.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-img
            contain
            max-height="300"
            class="white--text align-end"
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FIMG_2245%20(2).JPG?alt=media&token=44bab9d0-c87c-449a-9176-8a341009a0dd"
          />
        </v-col>
        <v-col cols="12" sm="11">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              <b>Терапия искусством</b> (art therapy) — это использование готовых произведений
              искусства. В них, как зеркале, отражается бессознательное, проявляется незримое, все
              подавленное мгновенно всплывает и становится выпуклым. Это про увидеть, прожить,
              подсветить. Почему так происходит — потому что искусство вбирает опыт поколений,
              является вместилищем коллективного бессознательного, отражает основные архитипические
              символы, и именно поэтому «цепляет» огромное количество людей. Тот, кто ведет в этой
              терапии — должен очень хорошо знать, какие произведения и зачем он использует, хорошо
              понимать искусство. Кстати, метафорические карты — это именно терапия искусством, по
              крайне мере самые первые колоды.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-img
            contain
            max-height="300"
            class="white--text align-end"
            src="https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/blog%2FIMG_1117235.png?alt=media&token=b56a5d46-8218-4f34-a318-38fe67cb7448"
          />
        </v-col>
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-text :class="{ 'body-2': $vuetify.breakpoint.xs }" class="body-1 text-justify">
              <i
                >История первой колоды ассоциативных карт такова. В 1975-м году профессор
                искусствоведения и прогрессивный художник Эли Раман создал первую колоду
                ассоциативных карт «ОН». Он хотел дать возможность людям, не связанным напрямую с
                искусством, дать прикоснуться к высокому волшебству. Картины должны были помещаться
                в ладонь, а искусство должно было стать «для людей, а не для искусства». То есть
                войти в повседневную жизнь. Но получилось что-то гораздо более глубокое… <br />
                В 1981-м году в Канаде опубликовали первые «О-карты». Метафорические карты состояли
                из двух колод: ситуативные изображения авторства художника Эли Рамана и рамочные
                карты со словами, отобранными психотерапевтом Джозефом Шлихтером. В 1983-м году Эли
                Раман познакомился с Моритцом Эгетмейером, который также был уверен, что с помощью
                карт «О» можно побудить человека к откровенному разговору о себе и своих проблемах.
                И в 1985-м году Моритц Эгетмейер начинает издавать «О-карты» в специально открытом
                им «О-издательстве», т.к. ни одно издательство не соглашалось их производить. Так
                метафорические карты стали одной из техник арт-терапии. Сейчас же колод так много, и
                многие из них так далеки от искусства, что называть это арт-терапией сложно…</i
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </transition-group>
  </v-container>
</template>

<script>
export default {
  name: 'BlogText',
  metaInfo: {
    title: 'Что такое арт-терапия на самом деле?',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Давно размышляю над тем, что зачастую то, что называют арт-терапией — не арт-терапия. В большинстве случаев. Когда человек приходит на арт-терапию, у него есть определенные ожидания. Исходя из моего опыта — клиент ожидает, что он будет рисовать. И на самом деле, под арт-терапией в подавляющем большинстве случаев понимается именно творчество. То есть терапия творчеством.'
      }
    ]
  },
  components: {
    PageTitle: () => import('@/components/shared/PageTitle')
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    }
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
